import { graphql, Link } from "gatsby";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import TagManager from "../components/TagManager";

const Home = (props) => {
    const intro = props.data.intro;
    const site = props.data.site.siteMetadata;
    const services = props.data.services.edges;
    const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute && 'intro-image-absolute'} ${intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'}`;

    return (
        <Layout bodyClass="page-home">
            <TagManager />
            <SEO title={site.title} />
            <Helmet>
                <meta
                    name="description"
                    content="Incritech is a group of code artisans focused on building highly scalable and resilient software platforms for the modern web."
                />
            </Helmet>
            <noscript>
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MCXNGPD" height="0" width="0" style={{ display: "none", visibility: "hidden" }}>
                </iframe>
            </noscript>

            <div className="intro">
                <div className="container">
                    <div className="row justify-content-start">
                        <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
                            <div dangerouslySetInnerHTML={{ __html: intro.html }} />
                        </div>
                        {intro.frontmatter.intro_image && (
                            <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                                <img alt={intro.frontmatter.title} className={introImageClasses} src={intro.frontmatter.intro_image} />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {services.length > 0 && (
                <div className="strip">
                    <div className="container pt-6 pb-6 pb-md-10">
                        <div className="row justify-content-start">
                            {services.map(({ node }) => (
                                <div key={node.id} className="col-12 col-md-4 mb-1">
                                    <div className="service service-summary">
                                        <div className="service-content">
                                            <h2 className="service-title">
                                                <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                                            </h2>
                                            <p>{node.excerpt}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services\/.*/" } }
      sort: { fields: [frontmatter___weight], order: ASC }
      limit: 6
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    intro: markdownRemark(
      fileAbsolutePath: {regex: "/content/index.md/"}
    ) {
        html
        frontmatter {
          image
          intro_image
          intro_image_absolute
          intro_image_hide_on_mobile
          title
        }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
